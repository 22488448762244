<template>
  <b-card
    v-if="data"
    no-body
  >
    <!-- title  -->
    <b-card-header class="pb-0">
      <b-card-title>产品数量</b-card-title>
    </b-card-header>
    <!--/ title  -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">{{ data.cookiess.prePIA }}</h1>
          <small>待评估</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="data.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">新增产品</b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.cookiess.newCnt }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">正在PIA</b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.cookiess.inPIA }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">平均处理时间</b-card-text>
          <span class="font-large-1 font-weight-bold">{{ data.cookiess.avgPIADays }}天</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    // data: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  data() {
    return {
      data:{
        cookiess:{
          total:5021,
          newCnt:29,
          prePIA:1234,
          inPIA:500,
          inPIAPercent:'80%',
          afterPIA:30,
          avgPIADays:3,
        },
        series: [83],
      },
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['已完成PIA'],
        }
      },
    }
  },
}
</script>
